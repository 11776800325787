<template>
  <div class=".gg-container-1">
    <div class="search-container">
      <!--输入框-->
      <div class="search-container-fn-input">
        <label>医助(导诊)姓名：</label>
        <el-input
          size="small"
          placeholder="请输入医助(导诊)姓名"
          prefix-icon="el-icon-search"
          clearable
          @change="reset"
          v-model="searchParams.name"
        >
        </el-input>
      </div>
			<el-button
				size="mini"
				type="primary"
				style="padding: 6px 7px;margin-left: 5px;
                              background-color: #2362FB; border-color: #2362FB"
				@click="reset"
			>
				<span>查找</span>
			</el-button>
      <div
        style="margin-left: auto; display: flex; justify-content: space-around"
      >
        <div class="search-container-fn-input" v-if="operateList.indexOf('delete') > -1">
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-refresh"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
								  background-color: #2362FB; border-color: #2362FB"
            @click="
              handleDelete(multipleSelectionPres[0])
            "
            v-if="multipleSelectionPres.length == 1"
            >删除
          </el-button>
        </div>
        <div class="search-container-fn-input" v-if="operateList.indexOf('update') > -1">
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-refresh"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
								  background-color: #2362FB; border-color: #2362FB"
            @click="handleEditAssistant(multipleSelectionPres[0])"
            v-if="multipleSelectionPres.length == 1"
            >编辑
          </el-button>
        </div>
        <div class="search-container-fn-input" v-if="operateList.indexOf('update_pwd') > -1">
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-refresh"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
								  background-color: #2362FB; border-color: #2362FB"
            @click="handleEditPwd(multipleSelectionPres[0])"
            v-if="multipleSelectionPres.length == 1"
            >修改密码
          </el-button>
        </div>
        <div class="search-container-fn-input" v-if="operateList.indexOf('bind_doctor') > -1">
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-refresh"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
								  background-color: #2362FB; border-color: #2362FB"
            @click="handleBindDoc(multipleSelectionPres[0])"
            v-if="multipleSelectionPres.length == 1 && multipleSelectionPres[0].type == 5"
            >绑定医生
          </el-button>
        </div>
        <div class="search-container-fn-input" v-if="operateList.indexOf('add') > -1">
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-refresh"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
								  background-color: #2362FB; border-color: #2362FB"
            @click="addAssistant"
            >新建医助
          </el-button>
        </div>
        <div class="search-container-fn-input" v-if="operateList.indexOf('update_table') > -1">
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-edit-outline"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
									background-color: #2362FB; border-color: #2362FB"
            @click="handleEditTableHead"
            >编辑表头
          </el-button>
        </div>
        <div class="search-container-fn-input" v-if="operateList.indexOf('reset_query') > -1">
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-refresh"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
									background-color: #2362FB; border-color: #2362FB"
            @click="handleResetSearch"
            >重置查找
          </el-button>
        </div>
        <!--对应第三方图标的button样式-->
      </div>
    </div>
    <!----------------表格---------------->
    <el-table
      border
      size="mini"
      v-loading="listLoading"
      :header-cell-style="{ 'text-align': 'center' }"
      :data="tableData"
      height="725"
      @selection-change="handleSelectionChange1"
      style="width: 100%;z-index:0"
    >
      <el-table-column type="selection" width="55" align="center" />
      <el-table-column type="index" align="center" width="50">
      </el-table-column>
      <template v-for="(column, index) in tableHead">
        <el-table-column
          :prop="column.column_prop"
          :label="
            column.column_label_user_definition
              ? column.column_label_user_definition
              : column.column_label
          "
          :width="column.width ? column.width : ''"
          :key="index"
          align="center"
          v-if="column.field_type === 'textBtn' && column.visible === true"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <a
              style="font-size: 12px; color: #2379fb"
              @click.prevent="
                currUser = scope.row;
                getInfo(scope.row.doctors_id);
              "
            >
              {{ scope.row[column.column_prop] }}
            </a>
          </template>
        </el-table-column>
        <!-- <el-table-column
          :prop="column.column_prop"
          :label="
            column.column_label_user_definition
              ? column.column_label_user_definition
              : column.column_label
          "
          :width="column.width ? column.width : ''"
          :key="index"
          align="center"
          v-else-if="column.field_type === 'image' && column.visible === true"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <el-avatar :src="scope.row.head_portrait"></el-avatar>
          </template>
        </el-table-column> -->
        <el-table-column
          :sortable="tableHeadSortable.includes(column.column_prop)"
          :prop="column.column_prop"
          :label="
            column.column_label_user_definition
              ? column.column_label_user_definition
              : column.column_label
          "
          :width="column.width ? column.width : ''"
          :key="index"
          align="center"
          v-else-if="column.visible === true"
          show-overflow-tooltip
        >
        <template slot-scope="scope">
            <el-tag type="primary" v-if="column.column_prop == 'type'">{{ scope.row.type == 5 ? '医助' : '导诊' }}</el-tag>
            <a v-else-if="column.column_prop == 'is_bind'">{{scope.row[column.column_prop] == false ? '未绑定': '已绑定'}}</a>
            <a  v-else>{{scope.row[column.column_prop]}}</a>
          </template>
          <template #header>
            <el-popover
              placement="bottom"
              title=""
              min-width="160"
              trigger="click"
              v-if="
                searchTableHead.filter(
                  (item) => item.name == column.column_prop
                ).length > 0
              "
            >
              <span slot="reference" class="search-header">
                <span class="search-title">{{
                  column.column_label_user_definition
                    ? column.column_label_user_definition
                    : column.column_label
                }}</span>
                <i
                  style="margin-left: 3px;font-weight: bolder; font-size: 20px"
                  class="el-icon-search"
                  :class="{
                    isSearch: searchTableHead.filter(
                      (item) => item.name == column.column_prop
                    )[0].isSearch,
                    'el-icon-zoom-in': searchTableHead.filter(
                      (item) => item.name == column.column_prop
                    )[0].isSearch,
                  }"
                />
              </span>
              <el-input
                v-if="'mobile' == column.column_prop"
                size="mini"
                placeholder=""
                prefix-icon="el-icon-search"
                v-model="searchParams.mobile"
                clearable
                @change="handleSearch(column.column_prop, searchParams.mobile)"
              >
              </el-input>
              <el-input
                v-if="'realname' == column.column_prop"
                size="mini"
                placeholder=""
                prefix-icon="el-icon-search"
                v-model="searchParams.name"
                clearable
                @change="handleSearch(column.column_prop, searchParams.realname)"
              >
              </el-input>
            </el-popover>
            <span v-else>{{
              column.column_label_user_definition
                ? column.column_label_user_definition
                : column.column_label
            }}</span>
          </template>
        </el-table-column>
      </template>
    </el-table>

    <!----------------分页---------------->
    <!--<pagination :total="total" :page.sync="listQuery.page" -->
    <!--						:limit.sync="listQuery.limit"-->
    <!--						@pagination="getList"/>-->

    <pagination
      :total="total"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.limit"
      @pagination="getList"
    />
    <!--编辑表头-->
    <editTableHead
      :isActiveEditTableHead.sync="isActiveEditTableHead"
      @_getAdminFieldIndex="_getAdminFieldIndex"
      v-if="isActiveEditTableHead"
      @getList="getList"
      :table_type="table_type"
    >
    </editTableHead>
    <!--绑定医生-->
    <el-dialog
      title="绑定医生"
      :visible.sync="dialogFormVisible2"
      v-if="dialogFormVisible2"
    >
      <div class="" style="display: flex;margin-bottom: 20px;">
        <div class="search-container-fn-input">
          <label>医生姓名：</label>
          <el-input
            style="width: 200px"
            size="small"
            placeholder="请输入医师姓名"
            prefix-icon="el-icon-search"
            clearable
            @change="_getAssistantDocList"
            v-model="bindQuery.name"
          >
          </el-input>
        </div>
      </div>
      <el-table
        v-loading="loading"
        :row-key="getRowKeys"
        ref="multipleTable"
        :data="doctorsData"
        @selection-change="handleSelectionChange"
        border
        style="width: 100%"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column
          prop="doctor_name"
          label="医生姓名"
          align="center"
          width="180"
        >
        </el-table-column>
        <el-table-column
          prop="doc_code"
          align="center"
          label="医生编号"
          width="180"
        >
        </el-table-column>
        <el-table-column prop="mobile" align="center" label="手机号">
        </el-table-column>
        <el-table-column prop="ge_name" align="center" label="性别">
        </el-table-column>
        <el-table-column prop="depart_name" align="center" label="科室">
        </el-table-column>
      </el-table>
      <pagination
        v-show="bindTotal > 0"
        :total="bindTotal"
        :page.sync="bindQuery.page"
        :limit.sync="bindQuery.limit"
        @pagination="_getAssistantDocList"
      />
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible2 = false">取 消</el-button>
        <el-button type="primary" @click="saveBindDoc">确 定</el-button>
      </div>
    </el-dialog>
    <!--修改密码-->
    <el-dialog title="修改密码" :visible.sync="dialogFormVisible1">
      <el-form
        :model="form1"
        label-position="left"
        :rules="rules"
        ref="ruleForm"
      >
        <el-form-item label="医助姓名" :label-width="formLabelWidth">
          <span>{{ form1.realname }}</span>
        </el-form-item>
        <el-form-item label="登录账号" :label-width="formLabelWidth">
          <span>{{ form1.username }}</span>
        </el-form-item>
        <el-form-item
          label="登录密码"
          :label-width="formLabelWidth"
          prop="password_hash"
        >
          <el-input v-model="form1.password_hash" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible1 = false">取 消</el-button>
        <el-button type="primary" @click="saveEditPwd">确 定</el-button>
      </div>
    </el-dialog>

    <!--保存医助-->
    <el-dialog
      title="保存医助"
      :visible.sync="dialogFormVisible"
      v-if="dialogFormVisible"
    >
      <el-form
        :model="form"
        label-position="left"
        :rules="rules"
        ref="ruleForm"
      >
        <el-form-item label="医助头像">
          <div style="display: flex;align-items: center;padding-left: 50px;">
            <customUpload
              @on-success="(file) => onSuccess(file, 'head_portrait')"
              :fileUrl="form.head_portrait"
            ></customUpload>

            <div
              style="color: #a399a6; font-size: 14px;width:300px;margin-left: 20px;"
            >
              <p style="width:300px;">图片建议上传146(宽)*146(高)</p>
              <p style="width:300px;">(上传图片大小不要超过2M)</p>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="类型" :label-width="formLabelWidth" prop="type">
          <el-radio-group v-model="form.type" size="small">
            <el-radio :label="5" border>医助</el-radio>
            <el-radio :label="7" border>导诊</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label="医助姓名"
          :label-width="formLabelWidth"
          prop="realname"
        >
          <el-input v-model="form.realname" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item
          label="手机号"
          :label-width="formLabelWidth"
          prop="mobile"
        >
          <el-input v-model="form.mobile" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item
          label="机构"
          :label-width="formLabelWidth"
          prop="agent_id"
        >
          <el-select v-model="form.agent_id" filterable placeholder="请选择">
            <el-option
              v-for="item in agents"
              :key="item.id"
              :label="item.merchant_name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="性别" :label-width="formLabelWidth" prop="gender">
          <el-radio-group v-model="form.gender" size="small">
            <el-radio :label="1" border>男</el-radio>
            <el-radio :label="2" border>女</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label="登录账号"
          :label-width="formLabelWidth"
          prop="username"
        >
          <el-input v-model="form.username" autocomplete="off"></el-input>
        </el-form-item>

        <el-form-item
          label="登录密码"
          :label-width="formLabelWidth"
          prop="password_hash"
          v-if="type == 'add'"
        >
          <el-input v-model="form.password_hash" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveAssistant">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import customUpload from "@/components/customUpload";
import Pagination from "@/components/Pagination"; // secondary package based on el-pagination
import editTableHead from "@/components/editTableHead/editTableHead";
import { getAdminFieldIndex } from "@/api/drugs2.0";

import {
  getAssistantList,
  getAgentList,
  addAssistantApi,
  updateAssistantApi,
  deleteAssistantApi,
  updateAssistantPwdApi,
  getAssistantDocList,
  saveAssistantDoc,
  getMulDoctorsApi,
  bindMulDoctorsApi,
} from "@/api/audit/assistant";
import { updateDoctorInfo } from "@/api/audit/doctor.js";
import { Message } from "element-ui";
import { mapState } from "vuex";

export default {
  name: "assistantManage",
  async created() {
    console.log(111112, this.$store.state);
    await this._getAdminFieldIndex();
    this.getList();
    this._getAgents();
  },

  data() {
    return {
      table_type:'assistantManage',
      isActiveEditTableHead: false,
      multipleSelectionPres: [],
      multipleSelection: [],
      activities: [],
      tableHead: [],
      searchTableHead: [
        //控制搜索
        {
          name: "realname",
          isSearch: false,
        },
        {
          name: "mobile",
          isSearch: false,
        }
      ],
      tableHeadSortable: [
        //控制排序
        "created_at",
        "expired_at",
        "next_visit_at",
        "end_visit_at",
        "end_order_at",
      ],
      bind_type: "single",
      agents: [],
      formLabelWidth: "120px",
      form: {},
      mulAssistant: [],
      member_id: 0,
      doctorsData: [],
      form1: {},
      dialogFormVisible: false,
      dialogFormVisible1: false,
      doctorList: {},
      dialogFormVisible2: false,
      reviewed: false,
      info: {},
      showPop: false,
      type: "add",
      selected: false,
      clearFilter: false,
      dialogTitle: "",
      dialogType: "",
      datetimerange: [],
      currUser: {},
      dialogTableVisible: false,
      searchParams: {
        orderTime: [],
      },
      total: 0,
      listLoading: false,
      fullscreenLoading: false,
      listQuery: {
        page: 1,
        limit: 10,
        importance: undefined,
        title: undefined,
        type: undefined,
        sort: "+id",
      },
      bindTotal: 0,
      bindQuery: {
        page: 1,
        limit: 10,
      },
      tableData: [],
      options: [],
      ruleForm: {
        examine: "",
      },
      doctorIds: [],

      rules: {
        head_portrait: [
          { required: true, message: "请上传医助头像", trigger: "change" },
        ],
        type: [{ required: true, message: "请选择类型", trigger: "change" }],
        realname: [
          { required: true, message: "请输入医助姓名", trigger: "blur" },
        ],
        mobile: [{ required: true, message: "请填写手机号", trigger: "blur" }],
        agent_id: [
          { required: true, message: "请选择机构", trigger: "change" },
        ],
        gender: [
          { required: true, message: "请选择活动区域", trigger: "change" },
        ],
        username: [
          { required: true, message: "请填写登录账号", trigger: "change" },
        ],
        password: [
          { required: true, message: "请填写登录密码", trigger: "change" },
        ],
      },

      isActiveDialog: false,
      operateList: [],
      curDoctor: {},
      loading: false,
    };
  },
  watch: {},
  computed: {
    ...mapState({
      table_options: (state) => state.user.table_options,
    }),
  },
  components: {
    Pagination,
    // eslint-disable-next-line vue/no-unused-components
    customUpload,
    editTableHead,
  },
  mounted() {
    this.$store.dispatch("user/getOperates", this.$route.path).then((res) => {
      this.operateList = res;
    });
  },
  methods: {
    handleSelectionChange1(val) {
      console.log(val, "val");
      this.multipleSelection = [];
      this.multipleSelectionPres = [];
      val.forEach((item) => {
        this.multipleSelection.push(item.id);
        this.multipleSelectionPres.push(item);
      });
    },
    handleSearch(prop, value) {
      console.log(prop, value, "2312343");
      this.searchTableHead.forEach((item) => {
        if (item.name == prop) {
          if (value == "") {
            item.isSearch = false;
          } else {
            item.isSearch = true;
          }
        }
      });
      this.reset()
    },
    handleResetSearch() {
      this.searchParams = {
        is_wx: "",
        visit_level: "",
        buy_count: "",
      };
      this.searchTableHead.forEach((item) => {
        item.isSearch = false;
      });
      this.choiceDateCreateRecord = [];
      this.choiceDateExpire = [];
      this.choiceDateEndVisit = [];
      this.choiceDateEndOrder = [];
      this.choiceDateNextVisit = [];
      this.getList("restPage");
    },
    handleEditTableHead() {
      this.isActiveEditTableHead = true;
    },
    async _getAdminFieldIndex() {
      // try {
      //   this.listLoading = true;
      //   var params = {
      //     type: "assistantManage",
      //   };
      //   const res = await getAdminFieldIndex(params);
        this.tableHead = this.table_options.assistantManage;
      //   console.log(this.tableHead);
      // } catch (err) {
      //   //在此处理错误
      // } finally {
      //   this.listLoading = false;
      // }
    },
    handleSelect(row, index) {
      return row.type == 5;
    },
    //批量绑定
    mulBindAssistant() {
      this.bind_type = "mul";
      getMulDoctorsApi(this.bindQuery).then((res) => {
        this.listLoading = false;
        this.dialogFormVisible2 = true;
        if (res.code == 200) {
          this.doctorsData = res.data.list;
          this.bindTotal = res.data.total;
        }
      });
    },
    getRowKeys(row) {
      return row.id;
    },
    handleMulSelect(e) {
      this.mulAssistant = e;
    },
    handleSelectionChange(e) {
      this.doctorIds = e.map((v) => {
        return v.id;
      });
    },
    saveBindDoc() {
      if (this.doctorIds.length == 0) {
        return this.$message.error("请选择要绑定的医生！");
      }
      if (this.bind_type == "mul") {
        let uids = this.mulAssistant.map((v) => {
          return v.id;
        });
        if (uids.length == 0) {
          return this.$message.error("请选择医助！");
        }
        let params = {
          uids,
          doctor_ids: this.doctorIds,
        };
        bindMulDoctorsApi(params).then((res) => {
          this.loading = false;
          if (res.code == 200) {
            this.getList();
            this.dialogFormVisible2 = false;
          }
        });
      } else {
        saveAssistantDoc(this.member_id, this.doctorIds).then((res) => {
          this.loading = false;
          if (res.code == 200) {
            this.getList();
            this.dialogFormVisible2 = false;
          }
        });
      }
    },

    handleBindDoc(row) {
      this.bind_type = "single";
      this.member_id = row.id;

      this._getAssistantDocList();
    },
    _getAssistantDocList() {
      this.loading = true;
      getAssistantDocList(this.member_id, this.bindQuery).then((res) => {
        this.loading = false;
        this.dialogFormVisible2 = true;
        if (res.code == 200) {
          this.doctorsData = res.data.list;
          this.bindTotal = res.data.total;
          this._selectRow();
        }
      });
    },
    _selectRow() {
      console.log(`111111`, this.doctorsData);
      let list = [];
      this.doctorsData.forEach((row) => {
        if (row.o_id == 0) {
          this.$nextTick(() => {
            this.$refs.multipleTable.toggleRowSelection(row, true);
          });
        }
      });
    },
    handleDelete(row) {
      this.$confirm(
        "是否确定删除该医助，删除后该医助的医生绑定将同步删除?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          deleteAssistantApi(row.id).then((res) => {
            if (res.code == 200) {
              this.getList();
              this.$message({
                type: "success",
                message: "删除成功!",
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },

    handleEditAssistant(row) {
      this.type = "update";
      this.form = Object.assign({}, row);
      console.log(1111111111, this.form);
      this.dialogFormVisible = true;
    },
    //修改密码
    saveEditPwd() {
      updateAssistantPwdApi(this.form1.id, this.form1.password_hash).then(
        (res) => {
          if (res.code == 200) {
            this.dialogFormVisible1 = false;
            this.$message({
              type: "success",
              message: "修改成功！",
            });
          } else {
            this.$message.error(res.msg);
          }
        }
      );
    },
    handleEditPwd(row) {
      this.form1 = Object.assign({}, row);
      this.dialogFormVisible1 = true;
    },
    onSuccess(file, type, name) {
      if (name) {
        let data = this.form[type];
        if (!data) {
          data = {};
        }
        data[name] = file;
        this.$set(this.form, type, data);
      } else {
        let data = file;
        this.$set(this.form, type, data);
      }
    },
    _getAgents() {
      getAgentList().then((res) => {
        if (res.code == 200) {
          this.agents = res.data;
        }
      });
    },
    saveAssistant() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          let curAgent = this.agents.find((v) => v.id == this.form.agent_id);
          this.form.agent_name = curAgent ? curAgent.merchant_name : "";
          if (this.type == "add") {
            this._addAssistant();
          } else {
            this._updateAssistant();
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    _addAssistant() {
      addAssistantApi(this.form).then((res) => {
        if (res.code == 200) {
          this.dialogFormVisible = false;
          this.getList();
          this.$message.success("添加成功！");
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    //修改医助
    _updateAssistant() {
      console.log(22, this.form);
      updateAssistantApi(this.form.id, this.form)
        .then((res) => {
          if (res.code == 200) {
            this.dialogFormVisible = false;
            this.getList();
            this.$message.success("修改成功！");
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    addAssistant() {
      this.form = {};
      this.type = "add";
      this.dialogFormVisible = true;
    },
    //取消
    handleQX() {
      this.dialogFormVisible2 = false;
      this.getList();
    },
    //修改排序
    haneldSortSubmit(row) {
      console.log(row);
      let param = {
        show_sort: row.show_sort,
        id: row.doctors_id,
      };
      updateDoctorInfo(param).then((res) => {
        if (res.code == 200) {
          Message({
            message: "操作成功",
            type: "success",
          });
          this.dialogFormVisible2 = false;
          this.getList();
        }
      });
    },
    //排序
    handleSort(row) {
      console.log(row);
      this.doctorList = row;
      this.dialogFormVisible2 = true;
    },

    //暂未开放
    notOpen() {
      this.$message({
        message: "暂未开放",
        type: "warning",
      });
    },
    //清除高级搜索
    clearState() {
      this.searchParams.status = "";
      this.searchParams.upload_status = "";
    },
    approvalStatus(e) {
      this.searchParams.status = e;
    },
    clearText() {
      this.searchParams.doctor_name = "";
      this.searchParams.mobile = "";
      this.searchParams.depart_id = "";
      this.searchParams.professional = "";
      this.getList();
    },
    popoShow() {
      this.clearFilter = true;
      this.selected = true;
    },
    popoHide() {
      this.selected = false;
      this.clearFilter = false;
    },

    getList() {
      this.listLoading = true;
      this.fullscreenLoading = true;
      let params = {
        type: this.type,

        ...this.searchParams,
      };
      if (params.depart_id && params.depart_id.length == 2) {
        params.depart_id.splice(0, 1);
      }
      params.page = this.listQuery.page;
      params.limit = this.listQuery.limit;
      getAssistantList(params)
        .then((response) => {
          let data = response;

          if (data.code == 200) {
            let _data = data.data;
            console.log(11111111111111, _data);
            this.total = _data.total;
            this.listQuery.limit = Number(_data.per_page);
            this.tableData = _data.list;
          }
          this.fullscreenLoading = false;
          this.listLoading = false;
        })
        .catch((error) => {
          Message({
            message: error.message,
            type: "error",
          });
          this.listLoading = false;
          this.fullscreenLoading = false;
        });
    },
    reset() {
      // this.searchParams = {};
      this.listQuery.page = 1;
      this.getList();
    },

    editSuccess() {
      alert("编辑成功");
      this.dialogTableVisible = false;
      this.getList();
    },
  },
  filters: {},
};
</script>

<style scoped lang="scss">
.horizontal-collapse-transition {
  transition: 0s width ease-in-out, 0s padding-left ease-in-out,
    0s padding-right ease-in-out;
}

.doc-title {
  position: relative;
  height: 30px;

  img {
    width: 25px;
    height: 25px;
    position: absolute;
    top: 2px;
  }

  span {
    display: inline-block;
    margin-left: 30px;
    line-height: 30px;
    font-weight: bold;
  }
}

/deep/ .el-drawer {
  bottom: 0 !important;
}

/deep/ .el-drawer__header {
  padding: 0;
  margin: 0;
}

/deep/ .el-tabs__header {
  margin: 0;
}

/deep/ .el-drawer.rtl {
  overflow: auto;
  height: 91vh;
  bottom: 0;
  margin-top: 9vh;
  position: absolute;
}

/deep/ .el-drawer__container ::-webkit-scrollbar {
  display: none;
}

/*dialog对话框样式调整*/
/deep/ .el-dialog__body {
  padding-bottom: 0 !important;
}

/deep/ .el-dialog__footer {
  background-color: #fafafa;
  border-top: 1px solid #eaeaea;
  padding: 10px 30px 10px 0;
}

/*dialog对话框样式调整*/
/deep/ .el-dialog__body {
  padding-bottom: 0 !important;
}

/deep/ .el-dialog__footer {
  background-color: #fafafa;
  border-top: 1px solid #eaeaea;
  padding: 10px 30px 10px 0;
}

/*弹出框表单配置*/
/deep/ .el-form-item__label {
  padding-bottom: 0;
}

/deep/ .el-form-item__content {
  width: 280px;
}

.from-item-second {
  margin-left: 0;
}

/deep/ .from-item-third {
  .el-form-item__content {
    width: 540px;
  }

  .el-select {
    width: 540px !important;
  }
}

/deep/ .el-cascader {
  width: 280px;
}

/deep/ .el-select {
  width: 280px !important;
}

/deep/ .el-date-editor {
  width: 280px !important;
}

/*select 宽度配置*/
/deep/ .el-select {
  width: 85px;
}

.inquiry {
  font-size: 14px;
  font-weight: bold;

  .inquiryImg {
    display: flex;
    align-items: center;

    img {
      width: 20px;
      height: 20px;
    }

    span {
      display: inline-block;
      margin-left: 8px;
    }
  }
}

/*竖杠+后面文本的样式*/
.required {
  margin-top: 2px;
  display: inline-block;
  width: 4px;
  height: 14px;
  background-color: #2362fb;
  top: 20px;
}

.required + span {
  display: inline-block;
  margin-left: 4px;
  vertical-align: top;
  font-weight: bold;
  font-size: 14px;
}

.inquiryText {
  /deep/ .el-input {
    width: 100px;
  }
}

.inquiry {
  /deep/ .el-input {
    width: 100px;
  }
}

.select-container {
  /deep/ .el-input {
    width: 80px;
  }
}
</style>
