import request from '@/utils/request'

//医助列表
export function getAssistantList(query) {
	return request({
		url: '/admin/assistant',
		method: 'get',
		params: query
	})
}


// 医助列表
export function getAgentList(query) {
	return request({
		url: '/admin/agents',
		method: 'get',
		params: query
	})
}

// 添加 医助
export function addAssistantApi(data) {
	return request({
		url: '/admin/assistant',
		method: 'post',
		data
	})
}

// 修改 医助
export function updateAssistantApi(id,data) {
	return request({
		url: '/admin/assistant/'+id,
		method: 'put',
		data
	})
}

//删除医助
export function deleteAssistantApi(id) {
	return request({
		url: '/admin/assistant/'+id,
		method: 'delete',
	})
}

//修改医助密码
export function updateAssistantPwdApi(id,pwd) {
	return request({
		url: '/admin/assistant/editPwd/'+id,
		method: 'put',
		data:{password_hash:pwd}
	})
}

// 获取医助绑定医生列表
export function getAssistantDocList(id,params) {
	return request({
		url: '/admin/assistant/bindDoctors/'+id,
		method: 'get',
		params
	})
}


export function saveAssistantDoc(id,doctor_ids) {
	return request({
		url: '/admin/assistant/bindDoctors/'+id,
		method: 'post',
		data:{doctor_ids}
	})
}

export function getMulDoctorsApi(params) {
	return request({
		url: '/admin/assistant/getMulDoctors',
		method: 'get',
		params
	})
}

//批量绑定
export function bindMulDoctorsApi(data) {
	return request({
		url: '/admin/assistant/bindMulDoctors',
		method: 'post',
		data
	})
}













